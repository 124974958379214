import { Controller } from "@hotwired/stimulus"
import { func } from "prop-types";

export default class extends Controller {

  // static targets = ['image', 'imageInput']

  static values = {
    urlUpdate: String
  }

  connect() {
    console.log('WaitMeetingController connected');

    setInterval(() => {
      location.reload();
    }, 30000);
    if (document.getElementById("start-meeting")) {
      document.addEventListener("DOMContentLoaded", function () {
        document.getElementById("start-meeting").click();
      });
    }
  }


}




